
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint.js";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "enrollment summary",
  components: {
    Field,
    Datatable,
  },
  data() {
    return {
      api_url: "",
      trainee: {
        entity_id: "",
        training_institute_id: "",
        tranche_id: "",
      },
      allinstituteSummary: [
        {
          enroll_trainee:"",
          enroll_female_trainee:"",
          assessed_trainee:"",
          assessed_female_trainee:"",
          certification_trainee:"",
          certification_female_trainee:"",
          employment_trainee:"",
          employment_female_trainee:"",
        } 
      ],

      allBatchSummary: [
        {
          enroll_trainee:"",
          enroll_female_trainee:"",
          assessed_trainee:"",
          assessed_female_trainee:"",
          certification_trainee:"",
          certification_female_trainee:"",
          employment_trainee:"",
          employment_female_trainee:"",
        } 
      ],
    
      institutekey: 0,
      batchkey: 0,
      tolatEnroll: 0,
      tolatEnrollFemale: 0,
      totalAssessed: 0,
      totalAssessedFemale: 0,
      totalCertification: 0,
      totalCertificationFemale: 0,
      totalEmployment: 0,
      totalEmploymentFemale: 0,
      batchTolatEnroll: 0,
      batchTolatEnrollFemale: 0,
      batchTotalAssessed: 0,
      batchTotalAssessedFemale: 0,
      batchTotalCertification: 0,
      batchTotalCertificationFemale: 0,
      batchTotalEmployment: 0,
      batchTotalEmploymentFemale: 0,
      associations: [],
      institutes: [],
      tranches: [],
      instituteSummary: [],
      batchSummary: [],
      notenrollTraineeList: [],
      notEnComponentKey: 0,
      enComponentKey: 0,
      load: false,
      loading: false,
      tabIndex: ref(0),
      btnCheck: false,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    this.emitter.on("infos-updated", async () => {
     
    });
  },
  methods: {
    async associationList() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.associations = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("institute/list?entity_id=" + entity_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getInstituteSummary() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      if(entity_id){
        this.load = true;
        await ApiService.get("enrollment/institute_summary?entity_id=" + entity_id +'&tranche_id=' + this.trainee.tranche_id +'&institute_info_id=' + institute_info_id)
          .then((response) => {
            this.instituteSummary = response.data.data;
            this.allinstituteSummary = response.data.data;
            this.allinstituteSummary.forEach(el => {
              this.tolatEnroll += ~~el.enroll_trainee;
              this.tolatEnrollFemale += ~~el.enroll_female_trainee;
              this.totalAssessed = ~~el.assessed_trainee;
              this.totalAssessedFemale = ~~el.assessed_female_trainee;
              this.totalCertification = ~~el.certification_trainee;
              this.totalCertificationFemale = ~~el.certification_female_trainee;
              this.totalEmployment = ~~el.employment_trainee;
              this.totalEmploymentFemale = ~~el.employment_female_trainee;
            });
            this.institutekey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
        });
      }else{
        this.tabIndex = 0;
        Swal.fire({
          title: "Error!",
          html: "Please select training partner.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },

    async getBatchSummary() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      if(institute_info_id){
        this.load = true;
        await ApiService.get("enrollment/batch_summary?entity_id=" + entity_id +'&tranche_id=' + this.trainee.tranche_id +'&institute_info_id=' + institute_info_id)
          .then((response) => {
            this.batchSummary = response.data.data;
            this.allBatchSummary = response.data.data;
            this.allBatchSummary.forEach(el => {
              this.batchTolatEnroll += ~~el.enroll_trainee;
              this.batchTolatEnrollFemale += ~~el.enroll_female_trainee;
              this.batchTotalAssessed = ~~el.assessed_trainee;
              this.batchTotalAssessedFemale = ~~el.assessed_female_trainee;
              this.batchTotalCertification = ~~el.certification_trainee;
              this.batchTotalCertificationFemale = ~~el.certification_female_trainee;
              this.batchTotalEmployment = ~~el.employment_trainee;
              this.batchTotalEmploymentFemale = ~~el.employment_female_trainee;
            });
            this.batchkey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
        });
      }else{
        this.tabIndex = 0;
        Swal.fire({
          title: "Error!",
          html: "Please select training institute.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));

      if(this.tabIndex == 1){
        this.getInstituteSummary();
      }
      if(this.tabIndex == 2){
        this.getBatchSummary();
      }

    },
    
  },
});
